<template>
  <div id="strikedash">
    <div
      class="d-all ufc-dash"
    >
      <v-card class="text-center d-all">
        <v-card-title>
          <h2 class="mx-auto">
            Strike PowerUps
          </h2>
        </v-card-title>
        <v-card-title class="text-center mt-0">
          <div class="mx-auto">
            <p v-if="!dapperUser">
              Connect your Dapper Account to activate Strike PowerUps.
            </p>
            <v-btn
              v-if="!dapperUser"
              color="success"
              class="ml-1 mb-6"
              outlined
              dark
              @click="connectFlow"
            >
              Connect account
            </v-btn>
            <div
              v-if="dapperUser"
              class="mt-0 pt-0"
            >
              <template>
                <v-expansion-panels
                  flat
                  class="mt-0 pt-0"
                >
                  <v-expansion-panel
                    key="account"
                    flat
                    class="mt-0 pt-0"
                  >
                    <v-expansion-panel-header
                      v-if="!isMobile"
                      flat
                      class="mt-1 pt-1 mb-1 pb-1 text-center"
                    >
                      Account:
                      <v-chip>{{ dapperUser }}</v-chip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-header
                      v-else
                      flat
                      class="mt-1 pt-1 mb-1 pb-1"
                    >
                      <p>Account:<br>
                        <v-chip class="mt-1">{{ dapperUser }}</v-chip>
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-btn
                        href="https://ufcstrike.com/mycollection?utm_source=fantasykombat&utm_medium=ecosystem&utm_intent=link"
                        target="_blank"
                        outlined
                        small
                      >
                        UFC
                        Strike
                        Account
                      </v-btn>
                      <br>
                      <v-btn
                        href="https://accounts.meetdapper.com/home"
                        target="_blank"
                        outlined
                        small
                        class="my-2"
                      >
                        Dapper
                        Account
                      </v-btn>
                      <br>
                      <v-btn
                        outlined
                        small
                        @click="disconnectFlow"
                      >
                        Disconnect
                      </v-btn>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
            <br>
          </div>
        </v-card-title>

        <v-card-text>
          <div class="text-center">
            <a
              href="https://support.fantasykombat.com/en/article/strike-powerups-1n5ez5c/"
              target="_blank"
            >
              <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
              How Strike PowerUps work.</a>
            <br>
            <v-btn
              class="mt-3 mb-4"
              to="/fight-tournaments"
              outlined
            >
              View
              Tournaments
            </v-btn>
          </div>
          <div v-if="dapperUser">
            <template>
              <v-tabs
                v-model="tab"
                color="success"
              >
                <v-tab
                  key="1"
                  class="ml-1"
                >
                  My PowerUps
                </v-tab>
                <v-tab key="2">
                  Unlock <span
                  class="hidden-md-and-down mx-1"
                > additional </span>
                  PowerUps
                </v-tab>
              </v-tabs>
            </template>
            <v-tabs-items v-model="tab">
              <v-row class="mx-2 my-0  pu-search-dash">
                <v-col class="my-0">
                  <v-text-field
                    v-model="search"
                    clearable
                    color="success"
                    label="Search"
                    :prepend-inner-icon="icons.mdiMagnify"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mx-2 my-0 pu-filters">
                <v-col class="my-0 movf">
                  <v-select
                    v-model="movs"
                    :items="myMovKeys"
                    color="success"
                    label="Method of Victory"
                    placeholder="MoV"
                  ></v-select>
                </v-col>
                <v-col class="my-0">
                  <v-select
                    v-model="tiers"
                    :items="tierKeys"
                    color="success"
                    label="Tiers"
                  ></v-select>
                </v-col>
                <v-col class="my-0">
                  <v-country-select
                    v-model="country"
                    :country-name="false"
                    clearable
                    short-code-dropdown="true"
                    :disable-placeholder="true"
                    placeholder="Country"
                    class="country-select"
                  />
                </v-col>
                <v-col
                  class="my-0 col-md-3 reset-col"
                  cols="12"
                >
                  <v-btn
                    small
                    class="my-0 mt-4 reset-search-dash"
                    @click="resetFilter"
                  >
                    Reset<span class="hidden-md-and-down ml-1">Filters</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-tab-item key="1">
                <template>
                  <v-container
                    fluid
                    class="mt-0"
                  >
                    <v-data-iterator
                      :items="filteredMyNfts"
                      item-key="id"
                      :search="search"
                      :sort-by="['tier','name']"
                      :items-per-page="8"
                    >
                      <template v-slot:no-data>
                        <v-icon
                          v-if="puLoading"
                          class="pu-spinner"
                        >
                          {{ icons.mdiLoading }}
                        </v-icon>

                        <p
                          v-else
                          class="text-center mt-4"
                        >
                          No Matching PowerUps
                        </p>
                      </template>
                      <template v-slot:default="{ items }">
                        <v-row class="mt-0">
                          <v-col
                            v-for="item in items"
                            :key="item.id"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                          >
                            <v-card
                              class="card-moment"
                              elevation="8"
                              @click="viewNft(item)"
                            >
                              <v-chip
                                class="float-right mt-2 ml-1 mr-2 mb-0 pb-0 text-xs"
                                outlined
                              >
                                {{
                                  item.name|methode
                                }}
                              </v-chip>
                              <h3 class="text-left pl-4 pt-3 text-truncate">
                                {{
                                  item.name|name
                                }}
                              </h3>
                              <p
                                class="text-left text-xs pl-4 text-truncate strike-meta text-capitalize"
                              >
                                {{ item.name|event }}<br>
                                {{ item.tier }}
                              </p>
                              <div class="fade-in card-wrapper">
                                <div class="card-inner">
                                  <span class="ufc-mov">{{ item.name|methodeshort }}</span><br>
                                  <div class="ufc-name mb-0 pb-0">
                                    <div
                                      v-resize-text="{maxFontSize: '14px', minFontSize: '11px', delay: 200}"
                                      class="ufc-name-text"
                                    >
                                      {{ item.name|namefirst }}<br>
                                      <div class="text-truncate">
                                        {{ item.name|nameothers }}
                                      </div>
                                    </div>
                                  </div>
                                  <country-flag
                                    v-if="item.country"
                                    :country="item.country"
                                    size="normal"
                                    rounded
                                    class="pick-flag pb-0 mb-0 mt-0 pt-0 mx-auto text-center moment-flag"
                                  />
                                  <div
                                    v-else
                                    class="pick-flag pb-0 mb-0 mt-0 pt-0 mx-auto text-center moment-flag"
                                  >
                                    --
                                  </div>
                                </div>
                                <v-img
                                  class="moment-img"
                                  max-height="160"
                                  max-width="160"
                                  :lazy-src="require('@/assets/images/misc/spinner-dark.gif')"
                                  :src="`https://static.fantasykombat.com/images/${item.tier.toLowerCase()}.png`"
                                >
                                </v-img>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-container>
                </template>
              </v-tab-item>
              <v-tab-item key="2">
                <template>
                  <v-container
                    fluid
                    class="mt-1"
                  >
                    <v-data-iterator
                      :items="filteredListings"
                      item-key="uuid"
                      :items-per-page="8"
                      :search="search"
                      :sort-by="['price','name']"
                    >
                      <template v-slot:default="{ items }">
                        <v-row class="mt-4">
                          <small class="mb-3 mt-0">
                            * PowerUp collections are an indicative list only. FantasyKombat does
                            not
                            offer
                            or
                            sell
                            any corresponding UFC Strike Moments. To purchase click the “View”
                            button on
                            the
                            PowerUp, you will be redirected to the official site, www.ufcstrike.com.
                            Availability of the UFC Strike Moments and their pricing may vary on
                            the
                            official
                            UFC
                            Strike site.
                          </small>
                          <v-col
                            v-for="item in items"
                            :key="item.uuid"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                          >
                            <v-card
                              elevation="8"
                              class="card-moment-buy"
                            >
                              <v-chip
                                class="float-right mt-2 ml-1 mr-2 mb-0 pb-0 text-xs"
                                outlined
                              >
                                {{
                                  item.name|methode
                                }}
                              </v-chip>
                              <h3 class="text-left pl-4 pt-3 text-truncate">
                                {{ item.name|name }}
                              </h3>
                              <p class="float-right mr-4 mt-2 strike-price">
                                *${{ item.price }}
                              </p>
                              <p
                                class="text-left text-xs pl-4 mb-0 pt-0 text-truncate strike-meta text-capitalize"
                              >
                                {{ item.name|event }}<br>
                                {{ item.tier }}
                              </p>

                              <div class="fade-in card-wrapper">
                                <div class="card-inner">
                                  <span class="ufc-mov">{{ item.name|methodeshort }}</span><br>
                                  <div class="ufc-name mb-0 pb-0">
                                    <div
                                      v-resize-text="{maxFontSize: '14px', minFontSize: '11px', delay: 200}"
                                      class="ufc-name-text"
                                    >
                                      {{ item.name|namefirst }}<br>
                                      <div class="text-truncate">
                                        {{ item.name|nameothers }}
                                      </div>
                                    </div>
                                  </div>
                                  <country-flag
                                    v-if="item.country"
                                    :country="item.country"
                                    size="normal"
                                    rounded
                                    class="pick-flag pb-0 mb-0 mt-0 pt-0 mx-auto text-center moment-flag"
                                  />
                                  <div
                                    v-else
                                    class="pick-flag pb-0 mb-0 mt-0 pt-0 mx-auto text-center moment-flag"
                                  >
                                    --
                                  </div>
                                </div>
                                <v-img
                                  class="moment-img"
                                  max-height="160"
                                  max-width="160"
                                  :lazy-src="require('@/assets/images/misc/spinner-dark.gif')"
                                  :src="`https://static.fantasykombat.com/images/${item.tier.toLowerCase()}.png`"
                                >
                                </v-img>
                                <v-btn
                                  class="nftbuy mb-2"
                                  outlined
                                  :href="`https://ufcstrike.com/p2pmarketplace/${item.id}?utm_source=fantasykombat&utm_medium=ecosystem&utm_intent=buy`"
                                  target="_blank"
                                >
                                  View
                                </v-btn>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                    <p class="text stike-message text-capitalize font-italic">
                      {{
                        strikeMessage
                      }}
                    </p>
                  </v-container>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'
import axios from 'axios'
import {
  mdiCloseCircleOutline,
  mdiMagnify,
  mdiInformationOutline,
  mdiLoading,
} from '@mdi/js'
import firebase from 'firebase/app'
import 'firebase/firestore'
import dbFunctions from '@/db'

fcl.config({
  'discovery.wallet': 'https://accounts.meetdapper.com/fcl/authn-restricted',
  'discovery.wallet.method': 'POP/RPC',
  'accessNode.api': 'https://access-mainnet-beta.onflow.org',
  'app.detail.title': 'FantasyKombat',
  'app.detail.icon': 'https://www.fantasykombat.com/logo_small.png',
})

export default {
  filters: {
    name(value) {
      if (!value) return ''
      const text = value.toString()

      return text.split('|')[0]
    },
    namefirst(value) {
      if (!value) return ''
      const text = value.toString().split('|')[0]
      const allnames = text.split(' ')

      return allnames[0]
    },
    nameothers(value) {
      if (!value) return ''
      const text = value.toString().split('|')[0]
      const allnames = text.split(' ')
      const lastnames = []
      for (let i = 0; i < allnames.length; i += 1) {
        if (i > 0) {
          lastnames.push(allnames[i])
        }
      }

      return lastnames.join(' ')
    },
    methode(value) {
      if (!value) return ''
      const text = value.toString()

      return `${text.split('|')[2].replace(/\s+/g, '')}`
    },
    methodeshort(value) {
      if (!value) return ''
      let mov = value.split('|')[2].replace(/\s+/g, '')
      if (mov.toLowerCase() === 'submission') {
        mov = 'SUB'
      } else if (mov.toLowerCase() === 'grappling' || mov.toLowerCase() === 'striking') {
        mov = 'DEC'
      }
      const text = mov.toString()

      return text
    },
    event(value) {
      if (!value) return ''
      const text = value.toString()

      return `${text.split('|')[1]}`
    },
  },
  data() {
    return {
      isMobile: false,
      icons: {
        mdiCloseCircleOutline,
        mdiMagnify,
        mdiInformationOutline,
        mdiLoading,
      },
      tab: [],
      puLoading: false,
      disableCancel: false,
      user: this.$store.state.user,
      dapperUser: '',
      myNfts: [],
      allNfts: [],
      ufcStrikeListings: [],
      selectedItem: false,
      autoPowerUp: false,
      allFighters: {},
      allFightersUFCslugs: {},
      allFighterData: {},
      strikeMessage: '',
      showSwitchToolTip: false,
      search: '',
      country: '',
      sortBy: '',
      tiers: '',
      movs: '',
      keys: [1, 2, 3],
      tierKeys: [
        'All',
        'Contender',
        'Challenger',
        'Fandom',
        'Champion',
        'Ultimate',
      ],
      myMovKeys: [
        {text: 'All', value: 'All'},
        {text: 'KO/TKO', value: 'KO/TKO'},
        {text: 'Submission', value: 'SUB'},
        {text: 'Decision', value: 'DEC'},
      ],
    }
  },
  computed: {
    filteredListings() {
      return this.allNfts.filter(i => {
        let res = i
        if (this.movs !== 'All') {
          if (this.movs === 'DEC') {
            res = !this.movs || i.mov === 'DECS' || i.mov === 'DECG'
          } else {
            res = !this.movs || i.mov === this.movs
          }
        }

        return res
      }).filter(i => {
        let res = i
        if (this.tiers?.toLowerCase() !== 'all') {
          res = !this.tiers?.toLowerCase() || i.tier?.toLowerCase() === this.tiers?.toLowerCase()
        }

        return res
      }).filter(i => {
        let res = i
        if (this.country?.toLowerCase() !== 'All') {
          res = !this.country?.toLowerCase() || i.country?.toLowerCase() === this.country?.toLowerCase()
        }

        return res
      })
    },
    filteredMyNfts() {
      return this.myNfts.filter(i => {
        let res = i
        if (this.movs !== 'All') {
          if (this.movs === 'DEC') {
            res = !this.movs || i.mov === 'DECS' || i.mov === 'DECG'
          } else {
            res = !this.movs || i.mov === this.movs
          }
        }

        return res
      }).filter(i => {
        let res = i
        if (this.tiers?.toLowerCase() !== 'all') {
          res = !this.tiers?.toLowerCase() || i.tier?.toLowerCase() === this.tiers?.toLowerCase()
        }

        return res
      }).filter(i => {
        let res = i
        if (this.country.toLowerCase() !== 'All') {
          res = !this.country.toLowerCase() || i.country.toLowerCase() === this.country.toLowerCase()
        }

        return res
      }).filter(i => {
        let res = i
        if (this.country?.toLowerCase() !== 'All') {
          res = !this.country?.toLowerCase() || i.country?.toLowerCase() === this.country?.toLowerCase()
        }

        return res
      })
    },
  },
  mounted() {
    this.isMobile = this.$parent.$parent.$parent.$parent.$parent.isMobile // app.js
    this.init()
  },
  methods: {
    viewNft(item) {
      window.open(`https://ufcstrike.com/tokens/${item.id}?utm_source=fantasykombat&utm_medium=ecosystem&utm_intent=buy`, '_blank')
    },
    init() {
      this.puLoading = true
      if (this.user.profile.dapperAddress) {
        this.dapperUser = this.user.profile.dapperAddress
        dbFunctions.getAllFighters(['pending-update', 'active', 'archived', 'retired', 'archived', 'released']).then(res => {
          res.forEach(doc => {
            const data = doc.data()
            this.allFighterData[data.slug] = data
            let c = ''
            // eslint-disable-next-line
            let name = data.name
            if (data?.country) {
              c = data.country.toString()
            }
            if (data.ufc_strike_name) {
              name = data.ufc_strike_name
            }
            this.allFightersUFCslugs[data.ufc_slug] = {
              name: data.name,
              id: data.fighter_id,
              slug: data.slug,
              ufcslug: data.ufc_slug,
              ufc_strike_name: name,
              country: c,
            }
            this.allFighters[name] = {
              name,
              id: data.fighter_id,
              slug: data.slug,
              country: c,
            }
          })
          this.myStrikeNfts()
          this.buyStrikeNfts()
        })
      }
    },
    async buyStrikeNfts(search) {
      this.puLoading = true
      let nftsearch = search
      if (!nftsearch) {
        nftsearch = ''
      }
      axios({
        method: 'get',
        headers: {'Content-Type': 'application/json'},
        url: `https://us-central1-fantasykombat.cloudfunctions.net/getStrikeUFCListings?search=${nftsearch}`,
        withCredentials: false,
      }).then(res => {
        if (res.data?.message) {
          this.strikeMessage = `"We could not retrieve data from the marketplace right now, try again later: ${res.data?.message}`
        }
        if (res.data?.records) {
          for (let i = 0; i < res.data.records.length; i += 1) {
            let country = ''
            let tier = 'Contender'
            let movType = ''
            if (Object.keys(res.data.records[i]?.additional_metadata).length > 0) {
              if (this.allFighters[res.data.records[i]?.additional_metadata['ATHLETE NAME']]) {
                country = this.allFighters[res.data.records[i].additional_metadata['ATHLETE NAME']]?.country
              }
              if (!this.allFighters[res.data.records[i]?.additional_metadata['ATHLETE NAME']]) {
                console.log(`missing fighter ${res.data.records[i]?.additional_metadata['ATHLETE NAME']}`)
              }
              if (!country) {
                console.log(`missing country ${res.data.records[i]?.additional_metadata['ATHLETE NAME']}`)
              }

              if (res.data.records[i].additional_metadata?.TIER) {
                tier = res.data.records[i].additional_metadata?.TIER.toLowerCase()
                movType = res.data.records[i].additional_metadata['HIGHLIGHT CATEGORY']

                if (movType.toLowerCase() === 'submission') {
                  movType = 'SUB'
                } else if (movType.toLowerCase() === 'grappling') {
                  movType = 'DECG'
                } else if (movType.toLowerCase() === 'striking') {
                  movType = 'DECS'
                }
              }
              this.allNfts.push({
                name: res.data.records[i].set_name,
                price: res.data.records[i].min_storefront_price,
                id: res.data.records[i].id,
                tier,
                mov: movType,
                country,
              })
            } else {
              // console.log(res.data.records[i])
            }
          }
        }
        this.puLoading = false
      }).catch(err => {
        console.log(err)
      })
    },
    async myStrikeNfts() {
      this.myNfts = []

      // get owned NFT metadata
      this.puLoading = true
      const idsResponse = await fcl.send([
        fcl.script`
          import UFC_NFT from 0x329feb3ab062d289
          pub struct MetadataCollections {

            pub let items: {String : MetadataCollectionItem}
            pub let collections: {String : [String]}

            init(items: {String : MetadataCollectionItem}, collections: {String : [String]}) {
              self.items=items
              self.collections=collections
              }
          }

          pub struct MetadataCollection{
            pub let type: String
            pub let items: [MetadataCollectionItem]

            init(type:String, items: [MetadataCollectionItem]) {
                self.type=type
                self.items=items
                }
          }

          pub struct MetadataCollectionItem {
            pub let id:UInt64
            pub let name: String
            pub let image: String
            pub let url: String
            pub let listPrice: UFix64?
            pub let listToken: String?
            pub let mintQty: UInt32
            pub let setId: UInt32
            pub let seriesId: UInt32
            pub let metadata: {String: String}


            init(id:UInt64, name:String, image:String, url:String, listPrice: UFix64?, listToken:String?,  mintQty: UInt32, setId: UInt32, seriesId: UInt32, metadata: {String: String}) {
              self.id=id
              self.name=name
              self.url=url
              self.image=image
              self.listToken=listToken
              self.listPrice=listPrice
              self.mintQty=mintQty
              self.setId=setId
              self.seriesId=seriesId
              self.metadata=metadata
              }
            }

          pub fun main(account: Address): MetadataCollections? {
            let resultMap : {String : MetadataCollectionItem} = {}
            let results : {String :  [String]}={}
            let acct = getAccount(account)
            let collectionRef = acct.getCapability<&UFC_NFT.Collection{UFC_NFT.UFC_NFTCollectionPublic}>(UFC_NFT.CollectionPublicPath)
            let collection = collectionRef.borrow()!
            let items: [String] = []
            for id in collection.getIDs() {
                  let nft = collection.borrowUFC_NFT(id: id)!
                  let metadata = UFC_NFT.getSetMetadata(setId: nft.setId)!
                  let seriesId = UFC_NFT.getSetSeriesId(setId: nft.setId)!
                  let image = metadata["image"]!
                  let data = UFC_NFT.getSetMaxEditions(setId: nft.setId)!
                  let contentType="video"
                  let item = MetadataCollectionItem(
                      id: id,
                      name: metadata["name"]!,
                      image: image,
                      url: "https://www.ufcstrike.com",
                      listPrice: nil,
                      listToken: nil,
                      mintQty: UFC_NFT.getSetMaxEditions(setId: nft.setId)!,
                      setId: nft.setId,
                      seriesId: seriesId,
                      metadata: metadata
                      )

                  let itemId="UFC".concat(id.toString())
                  items.append(itemId)
                  resultMap[itemId] = item
            }

            if items.length != 0 {
              results["UFC"] = items
            }
            return MetadataCollections(items: resultMap, collections:results)
          }
        `,
        fcl.args([fcl.arg(this.dapperUser, t.Address)]),
      ])
      const myNFTs = await fcl.decode(idsResponse)

      for (let i = 0; i < Object.entries(myNFTs.items).length; i += 1) {
        const item = Object.entries(myNFTs.items)[i][1]
        if (item.metadata?.TIER) {
          let movType = item.metadata['HIGHLIGHT CATEGORY'] || item.metadata['HIGHLIGHT TYPE']
          if (movType === 'Submission') {
            movType = 'SUB'
          } else if (movType.toLowerCase() === 'grappling') {
            movType = 'DECG'
          } else if (movType.toLowerCase() === 'striking') {
            movType = 'DECS'
          }
          let c = ''
          if (this.allFighters[item.metadata['ATHLETE NAME']]) {
            c = this.allFighters[item.metadata['ATHLETE NAME']]?.country
          }
          if (!this.allFighters[item.metadata['ATHLETE NAME']]) {
            console.log(`missing fighter ${item.metadata['ATHLETE NAME']}`)
          }
          if (!c) {
            console.log(`missing country ${item.metadata['ATHLETE NAME']}`)
          }
          this.myNfts.push({
            name: item.name,
            price: null,
            id: item.id,
            tier: item.metadata?.TIER.toLowerCase(),
            mov: movType,
            country: c,
          })
        }
      }
      this.puLoading = false
    },
    async connectFlow() {
      fcl.authenticate().then(e => {
        this.dapperUser = e.addr
        const fields = {dapperAddress: e.addr}
        window.$crisp?.push(['set', 'session:event', [[['dapperWalletConnect', {status: 'completed'}, 'green']]]])
        if (!this.user.profile.dapperAddressCreated) {
          fields.dapperAddressCreated = firebase.firestore.FieldValue.serverTimestamp()
        }
        dbFunctions.updateUsersProfile(this.user, fields).catch(err => {
          console.log(err)
        }).then(() => {
          this.init()
          window.$crisp?.push(['set', 'session:event', [[['dapperWalletConnect', {status: 'started'}, 'blue']]]])
        })
      })
    },
    async disconnectFlow() {
      fcl.unauthenticate()
      this.disConnectFlow()
    },
    resetFilter() {
      this.owner = ''
      this.movs = ''
      this.tiers = ''
      this.country = ''
      this.search = ''
    },
    async disConnectFlow() {
      this.myNfts = []
      const fields = {dapperAddress: ''}
      fields.dapperAddressCreated = firebase.firestore.FieldValue.serverTimestamp()
      dbFunctions.updateUsersProfile(this.user, fields).then(() => {
        this.dapperUser = ''
        this.user.profile.dapperAddress = ''
      })
        .catch(err => {
          console.log(err)
        })
    },
  },
}

</script>
<style lang="scss">
#strikedash label.v-label.theme--dark {
  font-size: 14px;
}

#strikedash .country-select span.v-icon.notranslate.v-icon--svg.theme--dark {
  fill: #fff !important;
}
</style>

<style lang="scss" scoped>

.mobile #strikedash .pu-filters span.v-icon.notranslate.theme--dark {
  right: -11px !important;
}

.mobile .ufc-dash .v-slide-group__prev {
  display: none !important;
}

.mobile .row.mx-2.my-0.pu-filters {
  transform: scale(0.6);
  width: 153%;
  transform-origin: left;
}

.mobile .movf.col {
  margin-left: 16px;
}

.v-input.coin-amount {
  width: 333px;
  margin: auto;
}

.pu-spinner {
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#strikedash .v-window-item.v-window-item--active {
  margin-top: -23px;
}

#strikedash .v-data-footer {
  margin-top: 20px;
}

#strikedash label.v-label.theme--dark {
  font-size: 14px;
}

#strikedash .country-select span.v-icon.notranslate.v-icon--svg.theme--dark {
  fill: #fff !important;
}

.mobile .reset-search-dash {
  top: -45px;
}

.mobile .reset-col {
  height: 30px;
}

.v-input.coin-amount input {
  margin: auto;
  display: inline;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.v-image__image--preload {
  filter: blur(0px);
}

row.pu-search-dash .col {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.row.pu-search-dash {
  height: 50px;
  margin: 0;
  padding: 0;
}

.row.pu-search-dash.col-12 {
  height: 22px;
  margin: 0;
  padding: 0;
}

.nftbuy {
  margin-top: -46px;
  background: #000;
  box-shadow: 0px 0px 6px #fff;
}

.v-data-iterator .theme--dark.v-card {
  background: #000 !important;
}

.theme--dark.v-toolbar.v-sheet {
  background-color: transparent;
}

.fade-in {
  animation: fadeIn 2s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.v-image.v-responsive.moment-img.theme--dark {
  margin: auto;
  top: -117px;
}

.card-moment {
  max-height: 240px;
}

.card-moment-buy {
  max-height: 280px;
}

.strike-meta {
  line-height: 17px !important;
}

.strike-price {
  position: absolute;
  right: 3px;
}

a.nftbuy {
  margin-top: -197px;
}

#strikedash .card-inner {
  position: relative;
  font-weight: 800;
  color: #fff;
  margin: auto;
  top: 11.7px;
  max-height: 96px;
  max-width: 125px;
}

#strikedash span.ufc-mov {
  display: inline-block;
  z-index: 2;
  position: relative;
  margin: auto;
  font-size: 14px;
  padding-bottom: 0;
  width: 72px;
  top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#strikedash div.ufc-name {
  display: block;
  z-index: 2;
  position: relative;
  margin: auto;
  background: #222;
  width: 108px;
  height: 44px;
  padding: 4px 0px 1px 5px;
  border-radius: 2px;
  border-top: 2px solid #777;
  border-bottom: 2px solid #777;
  margin: auto;

}

#strikedash div.ufc-name-text {
  width: 100px !important;
  display: block;
}

#strikedash span.pick-flag.moment-flag {
  z-index: 2;
  display: inline-block;
  position: relative;
  margin: auto;
  top: -4px;
}

#strikedash .country-select path {
  stroke: #ddd;
  fill: #ddd;
}

#strikedash .ufc-dash .country-select svg {
  height: 19px;
  opacity: 0.8;
  font-size: 12px;
}

#strikedash .ufc-dash label.v-label {
  font-size: 14px;
}

#strikedash .ufc-dash .v-select__selections {
  font-size: 14px;
  color: rgba(231, 227, 252, 0.68);
}

#strikedash .v-image.v-responsive.moment-img.theme--dark {
  left: -3.4px;
}
</style>
