<template>
  <div>
    {{ transactionStatus }}
    <Strike></Strike>
  </div>
</template>
<script>
import Strike from '@/views/components/UFCStrike.vue'

export default {
  components: {
    Strike,
  },
  data() {
    return {
      transactionStatus: '',
    }
  },
}
</script>
